<template>
  <div class="manage">
    <!-- 引入table数据 -->
    <el-table :data="userMsg" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="date" label="时间"> </el-table-column>
      <el-table-column prop="username" label="姓名"> </el-table-column>
      <el-table-column prop="phone" label="联系方式"> </el-table-column>
      <el-table-column prop="car" label="意向车型"> </el-table-column>
      <el-table-column prop="dealer" label="留言信息"> </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handlerEdit(scope.row)"
            >编辑</el-button
          >
          <!-- 点击删除留言 -->
          <el-button type="danger" size="mini" @click="handlerDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getMsgList, deleteMsgList } from "@/api";
import { mapState } from "vuex";
export default {
  name: "userMsgShow",
  data() {
    return {
      userMsg: [],
    };
  },
  methods: {
    // 测试 获取留言列表  页面点击删除或取消后 刷新页面
    getMsgStore() {
      this.$store.dispatch("getMsgList");
      // this.userMsg =
      // console.log(this.$store.state.user.msgList.data);
      // this.$forceUpdate();
      this.userMsg = this.$store.state.user.msgList.data;

      // Vue.nextTick(() => {
      //   this.userMsg = this.$store.state.user.msgList.data;
      // });
    },
    // 点击了编辑操作
    handlerEdit(item) {
      // console.log(item);
    },
    // 点击了删除操作
    handlerDelete(item) {
      try {
        this.$confirm(
          "此操作将在后台删除用户留言数据且无法恢复, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            deleteMsgList(item.id);

            this.$message({
              type: "success",
              message: "删除成功!",
            });
            // 删除操作完成后 刷新页面
            this.getMsgStore();

            // this.$router.go(0);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消删除",
            });
            // 删除操作完成后 刷新页面
            this.getMsgStore();
          });
      } catch (error) {
        alert(error.message);
      }
    },
  },
  mounted() {
    // 获取留言
    // getMsgList().then(({ data }) => {
    //   this.userMsg = data.data;
    // });
    this.getMsgStore();
  },
  computed: {
    // 获取search模块展示产品一共多少数据
    ...mapState({
      userMsg: (state) => state.user.msgList.data,
    }),
  },
  watch: {
    // userMsg() {
    //   this.getMsgStore();
    // },
  },
};
</script>

<style></style>
