<template>
  <div>
        <el-container>
      <el-aside width="auto">
        <CommonAside/>
      </el-aside>
      <el-container>
        <el-header>
          <Header/>
        </el-header>
        <!-- 在正文和head的中间区域，就需要tag组件 -->
          <common-tags/>
        <el-main>
          <!-- 路由出口，路由匹配到的组件将渲染到这里 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

  </div>
</template>

<script>
import CommonAside from '@/components/commonAside'
import Header from '@/components/Header'
import commonTags from '@/components/commonTags'
export default {
  components: { CommonAside,Header,commonTags },
}
</script>

<style scoped>
  .el-header {
    padding: 0;
  }
</style>