<template>
  <div class="header-content">
    <div class="l-content">
      <el-button
        @click="changeMenu"
        icon="el-icon-menu"
        size="mini"
      ></el-button>
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          v-for="(item, index) in tags"
          :key="index"
          :to="{ path: item.path }"
          >{{ item.label }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="r-content">
      <el-dropdown>
        <span class="el-dropdown-link">
          <img class="user" src="@/assets/images/lumma.png" alt="" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            ><span @click="clickCenter">个人中心</span></el-dropdown-item
          >
          <el-dropdown-item
            ><span @click="clickQuit">退出</span></el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  methods: {
    changeMenu() {
      this.$store.commit("collapseMenu");
    },
    // 点击了个人中心
    clickCenter() {
      this.$router.push("/center");
    },
    // 点击了退出登录
    clickQuit() {
      sessionStorage.removeItem("level");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("articleid");
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabsList,
    }),
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.header-content {
  padding: 0 20px;
  background-color: #333;
  height: 60px;
  display: flex;
  justify-content: space-between; //主轴上两端显示
  align-items: center; //纵轴上居中
  .text {
    font-size: 14px;
    color: #fff;
    padding-left: 10px;
  }
  .r-content {
    .user {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .l-content {
    display: flex;
    align-items: center;
    /deep/.el-breadcrumb__item {
      .el-breadcrumb__inner {
        font-weight: normal;
        &.is-link {
          color: #666;
        }
      }
      &:last-child {
        .el-breadcrumb__inner {
          color: #fff;
        }
      }
    }
    .el-button {
      &.el-button--default {
        &.el-button--mini {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
