import httpMock from "@/utils/mockRequest";
import http from "@/utils/request";

// 定义接口：Mock请求首页数据
export const getData = () => {
  return httpMock.get("/home/getData");
};

// 定义接口：Mock请求用户数据
export const getUser = (params) => {
  // 返回用户列表
  return httpMock.get("/user/getUser", params);
};

// 定义接口：Mock新增用户数据
export const addUser = (data) => {
  // 返回用户列表
  return httpMock.post("/user/add", data);
};

// 定义接口：Mock编辑用户数据
export const editUser = (data) => {
  // 返回用户列表
  return httpMock.post("/user/edit", data);
};

// 定义接口：Mock新增用户数据
export const delUser = (data) => {
  // 返回用户列表
  return httpMock.post("/user/delete", data);
};

// lumma
// 文章

// 定义接口：新增文章  国内文章
export const addArticle = (data) => {
  // 提交新增数据
  return http.post("/lumma/article", data);
};

// 定义接口：新增文章  国际文章
export const addArticleInternational = (data) => {
  // 提交新增数据
  return http.post("/lumma/international", data);
};

// 定义接口：新增文章  经销商文章
export const addArticleDealer = (data) => {
  // 提交新增数据
  return http.post("/lumma/dealer", data);
};

// 定义接口：获取国内文章
export const getArticleList = () => {
  // 提交新增数据
  return http.get("/lumma/article");
};
// 定义接口：获取国际文章
export const getInternalList = () => {
  // 提交新增数据
  return http.get("/lumma/international");
};
// 定义接口：获取经销商文章
export const getDealerList = () => {
  // 提交新增数据
  return http.get("/lumma/dealer");
};

// 记得写参数
// 定义接口：修改国内文章
export const putArticleList = (params) => {
  // 提交新增数据
  return http.put("/lumma/article", params);
};
// 定义接口：修改国际文章
export const putInternalList = (params) => {
  // 提交新增数据
  return http.put("/lumma/international", params);
};
// 定义接口：修改经销商文章
export const putDealerList = (params) => {
  // 提交新增数据
  return http.put("/lumma/dealer", params);
};

// 删除文章
// 定义接口：删除国内文章
export const deleteArticleList = (params) => {
  // 提交新增数据
  return http.delete("/lumma/article", {
    data: {
      id: params,
    },
  });
};
// 定义接口：删除国际文章
export const deleteInternalList = (params) => {
  // 提交新增数据
  return http.delete("/lumma/international", {
    data: {
      id: params,
    },
  });
};

// 定义接口：删除经销商文章
export const deleteDealerList = (params) => {
  // 提交新增数据
  return http.delete("/lumma/dealer", {
    data: {
      id: params,
    },
  });
};

// 留言列表
// 定义接口：获取留言列表
export const getMsgList = () => {
  // 提交新增数据
  return http.get("/lumma/msg");
};

// 定义接口：删除留言
export const deleteMsgList = (params) => {
  // 提交新增数据
  return http.delete("/lumma/msg", {
    data: {
      id: params,
    },
  });
};

// 登录
// 定义接口 进行登录
export const backLogin = (params) => {
  return http.post("/back/login", params);
};

// 定义接口 获取当前经销商发布的文章
export const backDealerNews = (params) => {
  return http.post("/back/dealer", params);
};
// 定义接口 退出登录
// export const quitLogin = (params) => {
//   return http.put("/lumma/login", params);
// };
