<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    @open="handleOpen"
    @close="handleClose"
    active-text-color="#ffd04b"
  >
    <h3>{{ isCollapse ? "虏曼" : "虏曼中国后台管理系统" }}</h3>
    <el-menu-item
      @click="changeMenu(item)"
      v-for="item in noChildren"
      :key="item.name"
      :index="item.name"
    >
      <i :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu
      v-for="item in hasChildren"
      :key="item.label"
      :index="item.label"
    >
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
        <el-menu-item @click="changeMenu(subItem)" :index="subItem.path">{{
          subItem.label
        }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      menuData: [
        {
          path: "/home",
          name: "home",
          label: "首页",
          icon: "s-home",
          url: "Home/Home",
        },

        {
          path: "/user",
          name: "user",
          label: "留言管理",
          icon: "user",
          url: "UserManage/UserManage",
        },
        {
          path: "/article",
          name: "article",
          label: "审核修改",
          icon: "video-play",
          url: "MallManage/MallManage",
        },
        {
          label: "文章发布",
          icon: "document",
          children: [
            {
              path: "/page1",
              name: "page1",
              label: "国内文章",
              icon: "setting",
              url: "Other/PageOne",
            },
            {
              path: "/page2",
              name: "page2",
              label: "国际文章",
              icon: "setting",
              url: "Other/PageTwo",
            },
            {
              path: "/page3",
              name: "page3",
              label: "经销商文章",
              icon: "setting",
              url: "Other/PageThree",
            },
          ],
        },
      ],
    };
  },
  computed: {
    // 数据分为两种，一种没有子菜单，一种有子菜单
    noChildren() {
      return this.menuData.filter((item) => !item.children);
    },
    hasChildren() {
      return this.menuData.filter((item) => item.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
  // 在mounted阶段，如果权限不够，则不展示 留言管理
  mounted() {
    let level = sessionStorage.getItem("level");
    if (level >= 1) {
      return;
    } else {
      // 权限不够，不展示 留言管理
      this.menuData[1].label = "";
      this.menuData[1].path = "";
      // 权限不够，无法展示 发布文章 国内文章 国际文章
      this.menuData[3].children.shift();
      this.menuData[3].children.shift();
    }
  },
  methods: {
    // 点击菜单，切换页面
    changeMenu(item) {
      // 当页面的路由与要跳转的路由不一致，才允许跳转
      if (
        this.$route.path != item.path &&
        !(this.$route.path == "/home" && item.path == "/")
      ) {
        this.$router.push(item.path);
      }
      this.$store.commit("selectMenu", item);
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu {
  height: 100vh;
  border-right: none;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
