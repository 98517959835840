<template>
  <div class="manage">
    <el-card class="box-card">
      <div class="grid-content bg-purple">
        <div class="user">
          <img src="../assets/images/lumma.png" alt="" />
          <div class="userinfo">
            <p class="name">Admin</p>
            <p class="access">超级管理员</p>
          </div>
        </div>
        <div class="login-info">
          <p>上次登录的时间：<span>2023-2-11</span></p>
          <p>上次登录的地点：<span>杭州</span></p>
        </div>
      </div>
    </el-card>
    <div class="btnArea">
      <el-button type="primary" @click="toHome">查看后台</el-button>
      <el-button type="danger">退出登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "userMsgShow",
  data() {
    return {
      tableData: [],
      userMsg: [],
    };
  },
  methods: {
    toHome() {
      this.$router.push("/home");
    },
  },
  mounted() {
    // 获取留言
    getMsgList().then(({ data }) => {
      this.userMsg = data.data;
    });
  },
};
</script>

<style lang="less" scoped>
.manage {
  height: 80vh;
  .btnArea {
    float: left;
    margin-top: 2vh;
    margin-left: 2vw;
    width: 30vw;
    height: 40vh;
  }
}
.box-card {
  width: 20vw;
}
</style>
