<template>
  <div class="tags">
    <el-tag
      v-for="(item,index) in tags"
      :key="item.path"
      :closable="item.name =='home'?false:true"
      size="small"
      :effect="$route.name === item.name? 'dark' : 'plain' "
      @click="changeMenu(item)"
      @close="closeMenu(item,index)"
      >
      {{item.label}}
    </el-tag>
  </div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
export default {
  name:'CommonTag',
  data() {
      return {
       
      };
  },
  methods:{
    // 
    ...mapMutations(['closeTag']),

    // 点击tag跳转的功能
    changeMenu(item){
      this.$router.push({ name:item.name })
    },
    // 点击tag删除，关闭当前页
    closeMenu(item,index){
      // length用来判断当前页面在总路由数据中的位置，是不是最后一个
      const length = this.tags.length -1
      // 如果删除的是最后一个，就跳转到路由数组中上一个页面的地址，否则直接删除
      if(index == length){
        // 调用store中的mutation，删除state的数据
        this.closeTag(item)
        this.$router.push(this.tags[length-1].path)
      }else{
        this.closeTag(item)
        this.$router.push(this.tags[index].path)

      }
    }
  },
  computed:{
    ...mapState({
      tags:state=> state.tab.tabsList
    })
  }
}
</script>

<style lang="less" scoped>
  .tags{
    padding: 10px;
    padding-bottom: 3px;
    .el-tag{
      margin-right: 15px;
      cursor: pointer;
    }
  }

</style>