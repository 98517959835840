<template>
  <div class="rootEdit">
    <!-- 官网发出的文章  先到一个数据库中存储 经过审核 修改后 再存入正式文章发布库 -->

    <!-- 外边框 -->
    <div class="border">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="国内文章" name="first">
          <div class="cnEdit">
            <newsOneEdit v-show="this.level"></newsOneEdit>
          </div>
        </el-tab-pane>
        <el-tab-pane label="国际文章" name="second" v-show="this.level">
          <div class="interNationalEdit">
            <newsTwoEdit v-show="this.level"></newsTwoEdit>
          </div>
        </el-tab-pane>
        <el-tab-pane label="经销商文章" name="third">
          <div class="dealerEdit">
            <newsThreeEdit></newsThreeEdit>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// 文章列表预览组件
import newsOneEdit from "./newsOneEdit.vue";
import newsTwoEdit from "./newsTwoEdit.vue";
import newsThreeEdit from "./newsThreeEdit.vue";
export default {
  data() {
    return {
      level: 1,
      activeName: "first",
    };
  },
  components: {
    newsOneEdit,
    newsTwoEdit,
    newsThreeEdit,
  },
  // 判断权限，如果权限不够，不展示国内新闻 国际新闻功能
  mounted() {
    let level = sessionStorage.getItem("level");
    if (level >= 1) {
      return;
    } else {
      // 权限不够，不展示 留言管理
      this.level = 0;
    }
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.rootEdit {
  width: 87vw;
  height: 90vh;
  .border {
    width: 80vw;
    height: 80vh;
    margin-left: 1vw;
  }
}
</style>
