<template>
  <el-table :data="this.showList[0]" border style="width: 100%">
    <el-table-column fixed prop="id" label="ID" width="150"> </el-table-column>
    <el-table-column prop="productName" label="文章标题" width="120">
    </el-table-column>
    <el-table-column prop="descriptionTags" label="描述标签" width="120">
    </el-table-column>
    <el-table-column prop="keywordTags" label="关键词标签" width="120">
    </el-table-column>
    <el-table-column prop="seoTags" label="SEO标题" width="300">
    </el-table-column>
    <el-table-column prop="date" label="日期" width="120"> </el-table-column>
    <el-table-column fixed="right" label="操作" width="100">
      <template slot-scope="scope">
        <el-button @click="lookOver(scope.row)" type="text" size="small"
          >查看</el-button
        >
        <el-button type="text" size="small" @click="edit(scope.row)"
          >编辑</el-button
        >
        <el-button type="text" size="small" @click="delArticle(scope.row)"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { getDealerList, deleteDealerList, backDealerNews } from "@/api";
export default {
  name: "newsShowList",
  data() {
    return {
      adminInfo: true,
      articleiddata: {
        articleid: "",
      },
      cnNews: [],
      showList: [],
      showList2: [],
      page: 0,
      delId: {
        id: "",
      },
      deleteNum: "",
    };
  },
  methods: {
    // 查看文章
    lookOver(item) {
      this.$router.push({
        path: "/look",
        query: {
          article: item,
        },
      });
    },
    // 编辑文章
    edit(item) {
      this.$router.push({
        path: "/editthree",
        query: {
          article: item,
        },
      });
    },
    // 删除文章
    delArticle(item) {
      // alert(item.id);
      this.deleteNum = item.id;
      this.delId.id = this.deleteNum;

      this.$confirm("此操作将在官网删除文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDealerList(this.delId.id);
          this.$message({
            type: "success",
            message: "发布成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消发布",
          });
        });
    },
  },
  created() {
    // 只显示自己经销商发布的文章
    let sessionArticleid = sessionStorage.getItem("articleid");
    if (!sessionArticleid) {
      // 判断为管理员身份
      console.log("管理员身份");
      // 如果没有articleid，说明是超级管理员，执行正常逻辑
      getDealerList().then(({ data }) => {
        this.cnNews = data.data;
        this.showList.push(data.data);
        this.showList[0].reverse();
      });
      return;
    } else {
      // 判断为经销商身份
      this.adminInfo = false;
      this.articleiddata.articleid = sessionArticleid;
      console.log("经销商身份");

      backDealerNews(this.articleiddata).then(({ data }) => {
        this.cnNews = data.data;
        this.showList.push(data.data);
        this.showList[0].reverse();
      });
    }
  },
};
</script>
