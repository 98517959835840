<template>
  <div class="root">
    <!-- 选项1 -->
    <div class="box1">
      <el-input v-model="productName" placeholder="请输入产品名称"></el-input>
    </div>
    <!-- 选项2 -->
    <div class="box2">
      <el-input v-model="seoTags" placeholder="请输入SEO标题"></el-input>
    </div>
    <!-- 选项3 -->
    <div class="box3">
      <el-input v-model="keywordTags" placeholder="请输入关键词标签"></el-input>
    </div>
    <!-- 选项4 -->
    <div class="box4">
      <el-input
        v-model="descriptionTags"
        placeholder="请输入描述标签"
      ></el-input>
    </div>
    <!-- 选项5 -->
    <div class="box5">
      <el-input
        v-model="coverImg"
        placeholder="请输入文章封面图地址"
      ></el-input>
    </div>
    <!-- 文章分类 -->
    <div class="sort">
      <el-select v-model="sort" placeholder="请选择文章分类">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <!-- 文章编辑器 -->
    <div class="title">
      <Editor id="tinymce" v-model="value" :init="init"></Editor>
    </div>

    <div class="submit">
      <el-button round @click="clearContent" type="danger">清空内容</el-button>
      <el-button type="primary" round @click="open" class="confirm"
        >确认发布</el-button
      >
    </div>
  </div>
</template>

<script>
// 导入编辑器所需插件
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default/icons.min.js";
import "tinymce/themes/silver/theme";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/themes/silver";
import "tinymce/plugins/media";
import "tinymce/plugins/preview";
import "tinymce/plugins/advlist";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/charmap";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/autosave";
import "tinymce/plugins/autoresize";
import "tinymce/models/dom";

// 导入请求路由
import { addArticle, getArticleList } from "@/api";
export default {
  name: "cnNews",
  data() {
    return {
      // 产品名称
      productName: "",
      // seo标题
      seoTags: "",
      // 关键词标签
      keywordTags: "",
      // 描述标签
      descriptionTags: "",
      // 富文本编辑器内容
      value: "",
      // 文章分类
      sort: "",
      // sort文章分类的配置项
      options: [
        {
          value: "选项1",
          label: "V男爵",
        },
        {
          value: "选项2",
          label: "VS公爵",
        },
        {
          value: "选项3",
          label: "LD挑战者",
        },
        {
          value: "选项4",
          label: "LD挑战者堡垒版",
        },
        {
          value: "选项5",
          label: "X7泰坦",
        },
        {
          value: "选项6",
          label: "G770可汗",
        },
        {
          value: "选项7",
          label: "G770R骑士",
        },
        {
          value: "选项8",
          label: "新闻资讯",
        },
        {
          value: "选项9",
          label: "虏曼品牌",
        },
      ],
      // 封面图地址
      coverImg: "",

      contenyt: {},
      init: {
        contextmenu: false, // 禁用富文本的右键菜单，使用浏览器自带的右键菜单
        // 设置内容样式
        content_style: `

          *                         { padding:0; margin:0; }

          html, body                { height:100%; }

          img                       { max-width:100%; display:block;height:auto; }

          a                         { text-decoration: none; }

          iframe                    { width: 100%; }

          p                         { line-height:1.6; margin: 0px; }

          table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }

          .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }

          ul,ol                     { list-style-position:inside; }

        `,
        language_url: "/zh_CN.js", // 语言包位置，因为放在public下所以可以省略public
        selector: "#tinymce", //tinymce的id
        language: "zh_CN", //语言类型
        skin_url: "/skins/ui/oxide",
        height: 500, //编辑器高度
        browser_spellcheck: true, // 拼写检查
        branding: false, // 去水印
        paste_data_images: true, // 允许粘贴图像
        // menubar: false, //最顶部文字信息
        plugins: "image link lists wordcount",
        branding: false, //是否禁用“Powered by TinyMCE”
        toolbar:
          "fontselect fontsizeselect link lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | image quicklink h2 h3 blockquote table numlist bullist preview fullscreen", //工具栏
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        plugins: "image link paste code lists wordcount", //就可以增加上面引入的插件，加入下面这一行就可以在toolbar栏显示相应插件。
        toolbar:
          "fontselect fontsizeselect link lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | image quicklink h2 h3 blockquote numlist bullist preview fullscreen", //工具栏
      },
      // 最终数组
      resultArray: {
        productName: "",
        seoTags: "",
        keywordTags: "",
        descriptionTags: "",
        value: "",
        sort: "",
        coverImg: "",
      },
    };
  },
  methods: {
    // 展示确认发布的弹出框
    open() {
      this.resultArray.productName = this.productName;
      this.resultArray.seoTags = this.seoTags;
      this.resultArray.keywordTags = this.keywordTags;
      this.resultArray.descriptionTags = this.descriptionTags;
      this.resultArray.value = this.value;
      this.resultArray.coverImg = this.coverImg;

      // sort的处理语句
      switch (this.sort) {
        case "选项1": {
          this.resultArray.sort = "V男爵";
          break;
        }
        case "选项2": {
          this.resultArray.sort = "VS公爵";
          break;
        }
        case "选项3": {
          this.resultArray.sort = "LD挑战者";
          break;
        }
        case "选项4": {
          this.resultArray.sort = "LD挑战者堡垒版";
          break;
        }
        case "选项5": {
          this.resultArray.sort = "X7泰坦";
          break;
        }
        case "选项6": {
          this.resultArray.sort = "G770可汗";
          break;
        }
        case "选项7": {
          this.resultArray.sort = "G770R骑士";
          break;
        }
        case "选项8": {
          this.resultArray.sort = "新闻资讯";
          break;
        }
        case "选项9": {
          this.resultArray.sort = "虏曼品牌";
          break;
        }
      }

      // 发布文章
      this.$confirm("此操作将在官网发布文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })

        .then(() => {
          addArticle(this.resultArray);
          this.$message({
            type: "success",
            message: "发布成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消发布",
          });
        });

      // 直接传递个对象，不转为json
    },
    // 清空发布内容
    clearContent() {
      this.value = "";
    },
  },
  components: { Editor },
  mounted() {
    tinymce.init({});
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.root {
  .box1 {
    width: 60%;
    background-color: yellowgreen;
  }
  .box2 {
    margin-top: 2vh;
    width: 60%;
    background-color: yellow;
  }
  .box3 {
    margin-top: 2vh;
    width: 60%;
  }
  .box4 {
    margin-top: 2vh;
    width: 60%;
  }
  .box5 {
    margin-top: 2vh;
    width: 60%;
  }
  .sort {
    margin-top: 2vh;
  }
  .title {
    margin-top: 2vh;
  }
  .submit {
    margin-left: 26vw;
    margin-top: 4vh;
    .confirm {
      margin-left: 6vw;
    }
  }
}
</style>
