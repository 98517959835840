<template>
  <div class="rootMsg">
    <!-- 验证码区域 -->
    <div class="msgArea">
      <!-- 图片展示栏 -->
      <div class="picShow">
        <img src="@/assets/images/msg.png" alt="" />
      </div>
      <!-- 随时了解虏曼的最新资讯 -->
      <div class="msg1">
        <p>虏曼中国 · 官网管理</p>
      </div>

      <!-- 验证码输入框 右下角二维码展示 -->
      <!-- 文字输入区域 -->
      <div class="input">
        <input
          type="text"
          class="inputArea"
          v-model="acount"
          placeholder="  请输入账号:"
        />
        <br />
        <input
          type="text"
          class="inputArea"
          v-model="password"
          placeholder="  请输入密码:"
        />
        <br />
        <input
          type="text"
          placeholder="  验证码"
          class="inputCodeArea"
          v-model="code"
        />
        <div class="codeAuth">
          <img src="@/assets/images/code/1.png" title="更换验证码" />
        </div>
        <button class="submit" @click="clickSubmit">登录</button>
      </div>

      <div class="msg3">
        <!-- 二维码展示 -->
        <img src="@/assets/images/code.png" alt="" />
        <br />
        <span>获取更多资讯</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      acount: "",
      password: "",
      code: "",
    };
  },
  methods: {
    async clickSubmit() {
      // 车架号不为空
      if (!this.acount) {
        return alert("请输入账号");
      }
      // 密码不为空
      if (!this.password) {
        return alert("请输入密码");
      }
      //  判断验证码是否正确
      if (this.code != "0209") {
        return alert("验证码错误");
      }

      // 解构赋值，从this拿到phone和password
      try {
        const { acount, password } = this;

        await this.$store.dispatch("getUserLevel", {
          name: acount,
          password: password,
        });
        // 登陆成功，跳转到home首页
        // 登录的路由组件：看路由当中有没有要去而没去成，存储的query参数，有了去query，没了首页
        let toPath = this.$route.query.redirect || "/home";
        this.$router.push(toPath);
        console.log(toPath);
      } catch (error) {
        //  提示错误具体明细
        alert(error.response.data.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.rootMsg {
  width: 100%;
  height: 110vh;
  background-image: url("@/assets/images/brand1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.input {
  position: relative;
  top: 0vh;
  width: 36vw;
  height: 40vh;
  color: #000;
  margin-left: 10vw;
}
.msgArea {
  position: relative;
  left: 30vw;
  top: 10vh;
  background-color: #fff;
  width: 40vw;
  height: 86vh;

  .msg1 {
    margin-top: 1vh;
    width: 100%;
    height: 6vh;
    p {
      margin-left: 4%;
      font-weight: 400;
      text-align: center;
      font-size: 2.4vmin;
      line-height: 6vh;
    }
  }
  .msg2 {
    width: 100%;
    height: 29vh;
    margin-left: 4vw;
    p {
      width: 32vw;
      margin-top: 2vh;
      font-size: 1.8vmin;
    }
    button {
      width: 12vw;
      height: 5vh;
      color: #fff;
      font-size: 1.6vmin;
      background-color: #000;
    }
  }
  .msgBtn {
    margin-top: 2vh;
    width: 10vw;
    height: 6vh;
  }
  .callAfter {
    cursor: pointer;
    width: 8vw;
    background-color: #696969;
    color: #fff;
    font-size: 1.6vmin;
    height: 5vh;
    margin-top: 4vh;
    margin-left: 0vw;
  }
  .title {
    img {
      margin-left: 16.7vw;
      width: auto;
      height: 5vh;
    }
  }
  .picShow {
    img {
      width: 100%;
    }
  }
  .msg3 {
    margin-left: 28vw;
    margin-top: -4vh;
    width: 9vw;
    height: 15vh;

    span {
      margin-left: 10px;
      font-size: 1.6vmin;
    }
  }
  .inputArea {
    color: #000;
    border: 1px solid #000;
    margin-top: 2vh;
    width: 20vw;
    height: 5vh;
    margin-left: -0.6vw;
    background-color: #fff;
    margin-bottom: 2vh;
  }
  .inputCodeArea {
    margin-top: 2vh;
    color: #000;
    border: 1px solid #000;
    width: 9vw;
    height: 5vh;
    margin-left: -0.6vw;
    background-color: #fff;
  }
  .submit {
    width: 8vw;
    background-color: #696969;
    color: #fff;
    font-size: 1.8vmin;
    height: 5vh;
    margin-top: 0vh;
    margin-left: -0.6vw;
  }
  .codeAuth {
    cursor: pointer;
    position: relative;
    top: -5vh;
    left: 11vw;
    width: 6vw;
    height: 5vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
